import { ref } from '@vue/composition-api'

export default function addRatingConfig() {
  const status = ref({
    label: 'Active',
    value: true,
  })
  const arrayRatingConfig = [
    {
      title: '',
      languageCode: '',
    },
  ]
  const countRatingConfig = ref(1)
  return {
    status,
    arrayRatingConfig,
    countRatingConfig,
  }
}
